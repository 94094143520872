/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes recent versions of Safari, Chrome (including
 * Opera), Edge on the desktop, and iOS and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
import 'document-register-element';

/* polyfill ie11 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// Run `npm install --save intl`.
// import 'intl';
// import 'intl/locale-data/complete.js';
// import 'intl/locale-data/jsonp/en.js';

if (typeof SVGElement.prototype.contains == 'undefined') {
	SVGElement.prototype.contains = HTMLDivElement.prototype.contains;
}

// Fixes console.table for older browsers.
// if (console.table === undefined) {
//     console.table = function (data, columns: any[]) {
//         if (data.constructor === Object) {
//             if (!columns) {
//                 var columns = [];
//                 for (var prop in data[0]) {
//                     if (columns.indexOf(prop) === -1) {
//                         columns.push(prop);
//                     }
//                 }

//                 var header = '(index)';
//                 for (var p in columns) {
//                     header += ' | ';
//                     header += columns[p];
//                 }
//                 console.log(header);

//             } else if (typeof columns !== 'object') {
//                 var columns = [];
//                 for (var index in data) {
//                     for (var prop in data[index]) {
//                         if (columns.indexOf(prop) === -1) {
//                             columns.push(prop);
//                         }
//                     }
//                 }
//             } else {
//                 var header = '(index)';
//                 for (var p in columns) {
//                     header += ' | ';
//                     header += columns[p];
//                 }
//                 console.log(header);
//             }

//             for (var obj in data) {
//                 var entry = data[obj];
//                 var entryStr = obj + '';
//                 for (var j = 0; j < columns.length; j++) {
//                     entryStr += ' | ';
//                     entryStr += entry[columns[j]];
//                 }
//                 console.log(entryStr);
//             }

//         } else if (data.constructor === Array) {
//             if (!columns) {
//                 var columns = [];
//                 for (var prop in data[0]) {
//                     if (columns.indexOf(prop) === -1) {
//                         columns.push(prop);
//                     }
//                 }

//                 var header = '(index)';
//                 for (var p in columns) {
//                     header += ' | ';
//                     header += columns[p];
//                 }
//                 console.log(header);

//             } else if (typeof columns !== 'object') {
//                 var columns = [];
//                 for (var index in data) {
//                     for (var prop in data[index]) {
//                         if (columns.indexOf(prop) === -1) {
//                             columns.push(prop);
//                         }
//                     }
//                 }
//             } else {
//                 var header = '(index)';
//                 for (var p in columns) {
//                     header += ' | ';
//                     header += columns[p];
//                 }
//                 console.log(header);
//             }

//             for (var i = 0; i < data.length; i++) {
//                 var entry = data[i];
//                 var entryStr = i + '';
//                 for (var j = 0; j < columns.length; j++) {
//                     entryStr += ' | ';
//                     entryStr += entry[columns[j]];
//                 }
//                 console.log(entryStr);
//             }
//         }
//     }
// }
